import {timestampToDateFormat} from '@/assets/js/util';

export const getScore = (learn, current) => {
  if(current === '2066015'){
    if(learn.accumPlaySec > 0){
      return `${Math.round(learn.accumPlaySec/60)}분` + (learn.progMdfcnDt ? `<br/> (${timestampToDateFormat(learn.progMdfcnDt, 'yyyy.MM.dd hh:mm:ss')})` : '');
    }
  }else{
    if(learn.lrnRsltSn > 0){
      // 2022-12-01, 95% 이상일 경우 100%로 처리하도록 적용
      const rate = learn.totProgRate > 100 ? 100 : learn.totProgRate;

      if (rate > 0) {
        return `${rate >= 95 ? 100 : Math.ceil(rate) }%`;
      } else {
        return `0%`;
      }
    }
  }
  return '-';
}

const standardMinute = (60 * 40);

const completeText = (eduEndDt) => {
  return eduEndDt > 0 ?
      `<span class="text-primary">완료<br>(${timestampToDateFormat(eduEndDt, 'yyyy.MM.dd hh:mm')})</span>` :
      `<span class="text-primary">완료</span>`;
}

export const getFinishText = (item, learn, current) => {
  // 입과 하였을 경우
  if(item.lrnTrgtGrpDtlSn > 0 && learn){
    if(current === '2066015'){
      // 변액보험 판매자격의 경우 콜드콜 여부를 사용하지 않는다.
      if (learn.accumPlaySec >= standardMinute) {
        return learn.eduEndDt > 0 ? `<span class="text-primary">완료<br>(${timestampToDateFormat(learn.eduEndDt, 'yyyy.MM.dd hh:mm')})</span>` : `<span class="text-dev-blue">완료</span>`;
      } else {
        return '<span class="text-muted">미완료</span>';
      }
    }else{
      const rate = learn.totProgRate > 100 ? 100 : learn.totProgRate;
      if (learn.fnshYn === 'Y') {
        return completeText(learn.eduEndDt);
      } else if (item.coldCallUseYn === 'Y') {
        if (rate >= 95 && (learn.maxPlaySec < 60 || learn.coldCallRspnsDt > 0)) return completeText(learn.eduEndDt);
        return '<span class="text-muted">미완료</span>';
      } else {
        if (rate >= 95) return completeText(learn.eduEndDt);
        return '<span class="text-muted">미완료</span>';
      }
    }
  }
  return '<span class="text-muted">미완료</span>';
}


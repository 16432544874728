import {computed, reactive, ref} from 'vue';
import {
  dateToDatePart, getItem, lengthCheck, setParams,
  timestampToDateFormat,
  ymdStrToFormat,
} from '@/assets/js/util';
import {
  sortCourseItems,
  sortKeyDesc,
  sortObjectItems,
} from '@/assets/js/modules/learn/learn-regular-sort';
import {
  getFinishText,
  getScore,
} from '@/assets/js/modules/learn/learn-regular-common';
import {useStore} from 'vuex';
import html2canvas from 'html2canvas';
import {ACT_GET_AUTH_INFO} from '@/store/modules/auth/auth';

export const learnRegularStatusSetup = (props, {emit}) => {
  const view = reactive({
    current: '2066013',
    tabs: [{name: '직무수행교육', key: '2066013'},{name: '방카슈랑스 판매자격', key: '2066015'},{name: '상품별 판매자격', key: '2066014'}]
  });

  const renderItems = computed(() => {
    const targetCourses = sortCourseItems(view.current, props.items.filter(x => x.splCrseTyCdDcd === view.current));
    if (view.current === '2066013') {
      return targetCourses.map(x => ({...x, learns: sortKeyDesc(sortObjectItems(x.crseNm, x.learns,  true), 'bgngDt')}));
    } else {
      return targetCourses.map(x => ({...x, learns: sortObjectItems(x.crseNm, x.learns,  false)}));
    }

  });

  const getColdCall = (learn, lrnTrgtGrpDtlSn) => {
    // 콜드콜 관련 이력이 존재한다.
    if(lrnTrgtGrpDtlSn > 0) {
      return `${learn.coldCallRspnsDt > 0 ? '응답' : '미응답'} ${learn.coldCallRspnsDt > 0 ? 1 : 0} / ${learn.coldCallRound}`
    }
    return '-';
  }

  const closeModal = () => {
    emit('update:toggle', false);
  }

  const starLinkStatuses = ['2066021'];

  return {
    view,
    renderItems,
    getColdCall,
    getScore,
    getFinishText,
    closeModal,
    timestampToDateFormat,
    starLinkStatuses
  }
}

export const learnRegularEthicsStatusSetup = (props, {emit}) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);
  const mainRef = ref(null);

  const ethicsSplCrseTyCdDcd = '2066019'
  const renderItems = computed(() => sortCourseItems(ethicsSplCrseTyCdDcd, props.items));
  const info = reactive({
    brdt: ''
  });

  const isCaptureMode = ref(false);

  const getStrWithDash = (brdt) => {
    if (brdt !== null) {
      if (brdt.length >= 8) {
        return ymdStrToFormat(brdt, '-');
      }else if (brdt.length >= 6) {
        return brdt.substr(0, 2) + '-' + brdt.substr(2, 2) + '-' + brdt.substr(4, 2);
      }
    }
    return '-';
  }

  const captureLearn = () => {
    if (isCaptureMode.value) return;

    isCaptureMode.value = true;
    setTimeout(() => {
      html2canvas(mainRef.value).then(canvas => {
        const link = document.createElement('a');
        link.download = `${props.params.crseNm}_${session.value.lrnerId}_${dateToDatePart(new Date()).ymd}.png`;
        link.href = canvas.toDataURL();
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(()=> {
        isCaptureMode.value = false;
      });
    }, 500);
  }

  const printLearn = () => {
    if (isCaptureMode.value) return;

    isCaptureMode.value = true;
    setTimeout(() => {
      const frame = document.createElement('iframe');
      frame.domain = document.domain;
      document.body.appendChild(frame);
      html2canvas(mainRef.value).then(function (canvas) {
        frame.contentDocument.head.innerHTML = '<style>@page { size: auto; margin: 0; } canvas {width: 100% !important; height: 100% !important;}</style>';
        frame.contentDocument.body.appendChild(canvas);
        frame.focus();
        frame.contentWindow.print();
        frame.parentNode.removeChild(frame);
        frame.remove();
      }).finally(() => {
        isCaptureMode.value = false;
      });

      window.focus();
    }, 500);
  }

  const closeModal = () => {
    emit('update:toggle', false);
  }

  const toggles = reactive({
    download: false,
  })

  const getAddSessionInfo = () => {
    store.dispatch(`auth/${ACT_GET_AUTH_INFO}`).then(res => {
      if (lengthCheck(res)) {
        setParams(info, getItem(res))
      }
    });
  }

  getAddSessionInfo();

  return {
    session,
    info,
    renderItems,
    toggles,
    getScore,
    getFinishText,
    getStrWithDash,
    mainRef,
    isCaptureMode,
    captureLearn,
    printLearn,
    closeModal,
    timestampToDateFormat,

  }

}

